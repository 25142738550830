import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';
import {
  CustomLLMColumnWithOverflowHidden,
  CustomLLMColumn,
  HeaderWrapper,
  HorizontalGroup,
  SpecificConfigurationHorizontalGroup,
  MessageAlignLeft,
  OverflowYScroll,
} from '../../Style';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputWrapper } from '../../../../Components/Input/Style';
import { buildCurlRequestFromData, convertStringToCurlRequest, CurlRequest } from '../../../../Tools/Curl';
import { convertObjectToKeyValueArray } from '../../../../Tools/Array';

interface ModalIdentityProviderConfigurationProps {
  visible: boolean;
  onHide: () => void;
  specificConfiguration?: API_BACKEND.Schemas.LLMCustomBotConfigurationSpecificConfiguration;
  saveIdpProviderData: (tokenEndpoint: string, idpHeaders: KeyValue[], idpParameters: KeyValue[]) => void;
}

const ModalIdentityProviderConfiguration = ({
  visible,
  onHide,
  specificConfiguration,
  saveIdpProviderData,
}: ModalIdentityProviderConfigurationProps) => {
  const ID: MutableRefObject<number> = useRef(0);

  const { t } = useTranslation();

  const [tokenEndpoint, setTokenEndpoint] = useState<string>(specificConfiguration?.tokenEndpoint || '');
  const [headers, setHeaders] = useState<KeyValue[]>(
    convertObjectToKeyValueArray(specificConfiguration?.idpHeaders) || []
  );
  const [parameters, setParameters] = useState<KeyValue[]>(
    convertObjectToKeyValueArray(specificConfiguration?.idpData) || []
  );

  const generateCurl = () => {
    return buildCurlRequestFromData(tokenEndpoint, headers, parameters);
  };

  const [curl, setCurl] = useState<string>(generateCurl());

  /**
   * Called when config is loaded, saved or restored
   */
  useEffect(() => {
    setTokenEndpoint(specificConfiguration?.tokenEndpoint || '');
    setHeaders(convertObjectToKeyValueArray(specificConfiguration?.idpHeaders, ID) || []);
    setParameters(convertObjectToKeyValueArray(specificConfiguration?.idpData, ID) || []);
  }, [specificConfiguration]);

  useEffect(() => {
    setCurl(generateCurl());
  }, [tokenEndpoint, headers, parameters]);

  const handleCurlChange = (e) => {
    setCurl(e.target.value);

    const curlRequest: CurlRequest = convertStringToCurlRequest(e.target.value);

    const headers: KeyValue[] = [];
    const params: KeyValue[] = [];

    curlRequest.headers.forEach((header) => headers.push({ ...header, id: ID.current++ }));
    curlRequest.parameters.forEach((param) => params.push({ ...param, id: ID.current++ }));

    setTokenEndpoint(curlRequest.endpoint);
    setHeaders(headers);
    setParameters(params);
  };

  const renderHeaderModal = (
    <HeaderWrapper>
      <h1 className='p-panel-title'>{t('externalContents.customLLM.specificConfiguration.vertexAI.idp.title')}</h1>
    </HeaderWrapper>
  );

  const renderListOfItems = (list: KeyValue[], setList: any) => {
    return list?.map((item: KeyValue, index) =>
      renderTemplateObj(
        index,
        item.key,
        item.value,
        (e) => {
          setList(
            list.map((itemToChange: KeyValue) =>
              itemToChange.id === item.id ? { ...itemToChange, key: e.target.value } : itemToChange
            )
          );
        },
        (e) => {
          setList(
            list.map((itemToChange: KeyValue) =>
              itemToChange.id === item.id ? { ...itemToChange, value: e.target.value } : itemToChange
            )
          );
        },
        () => {
          setList(list.filter((itemToChange) => itemToChange.id !== item.id));
        }
      )
    );
  };

  const renderTemplateObj = (index, key, value, onChangeName, onChangeValue, onClick) => {
    return (
      <div key={index} className='p-inputgroup flex-1 mt-2 mr-10'>
        <InputText placeholder='Name' value={key} onChange={onChangeName} />
        <InputText placeholder='Value' value={value} onChange={onChangeValue} />
        <Button style={{ width: '70px' }} icon={'icon-trash'} aria-label='Remove' onClick={onClick} />
      </div>
    );
  };

  return (
    <Dialog
      visible={visible}
      onHide={() => {
        saveIdpProviderData(tokenEndpoint, headers, parameters);
        onHide();
      }}
      dismissableMask={true}
      blockScroll={true}
      header={renderHeaderModal}
      style={{
        width: '80%',
        height: '80%',
        fontSize: '1em',
      }}
    >
      <CustomLLMColumn>
        <div className={'flex-grow-1'}>
          <SpecificConfigurationHorizontalGroup>
            <label htmlFor='tokenEndpoint'>
              {t('externalContents.customLLM.specificConfiguration.vertexAI.idp.tokenEndpoint')} :
            </label>
            <div className='p-inputgroup flex-1 mt-2 mr-2'>
              <Button label={'POST'} severity={'secondary'} />
              <InputText value={tokenEndpoint} onChange={(e) => setTokenEndpoint(e.target.value)} />
            </div>
          </SpecificConfigurationHorizontalGroup>

          <HorizontalGroup>
            <CustomLLMColumnWithOverflowHidden $maxHeight={'325px'}>
              <Button
                label={t('externalContents.customLLM.specificConfiguration.vertexAI.idp.addHeader')}
                icon='icon-add'
                className='mr-2'
                onClick={() => {
                  setHeaders([...headers, { id: ID.current++, key: '', value: '' }]);
                }}
              />
              <OverflowYScroll className={'mr-2'}>{renderListOfItems(headers, setHeaders)}</OverflowYScroll>
            </CustomLLMColumnWithOverflowHidden>
            <CustomLLMColumnWithOverflowHidden $maxHeight={'325px'}>
              <Button
                label={t('externalContents.customLLM.specificConfiguration.vertexAI.idp.addParameter')}
                icon='icon-add'
                className='mr-2'
                onClick={() => {
                  setParameters([...parameters, { id: ID.current++, key: '', value: '' }]);
                }}
              />
              <OverflowYScroll className={'pr-2'}>{renderListOfItems(parameters, setParameters)}</OverflowYScroll>
            </CustomLLMColumnWithOverflowHidden>
          </HorizontalGroup>
        </div>
        <InputWrapper>
          <MessageAlignLeft text={t('externalContents.customLLM.specificConfiguration.vertexAI.idp.pasteCurl')} />
          <InputTextarea className={'input-url'} value={curl} style={{ height: '200px' }} onChange={handleCurlChange} />
        </InputWrapper>
      </CustomLLMColumn>
    </Dialog>
  );
};

export default ModalIdentityProviderConfiguration;
