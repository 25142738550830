import styled from 'styled-components';
import docConnector from 'src/Assets/Images/doc-connector.png';
import Button from '../../../../../Components/Button/Button';
import { Message } from 'primereact/message';

export const DisplayContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin-bottom: 60px;
  align-items: flex-start;

  .p-panel {
    width: 100%;
    box-shadow: 0px 2px 4px #5060704d;
  }

  .files-wrapper,
  .sharepoint-wrapper,
  .website-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }

  .p-panel-title {
    display: flex;
    align-items: center;
    font-size: 21px;
    width: 100%;
    justify-content: center;
  }

  .p-panel-subtitle {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .uploadFile-header,
  .sharepoint-header,
  .website-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0 1rem 0;
    font-size: 20px;

    div {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 20px;
    }

    img {
      width: 50px;
    }
  }

  .actions-list {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row-reverse;
  }

  h1 {
    color: rgb(70, 82, 95);
    font-size: 21px;
  }

  h2 {
    color: rgb(116, 136, 157);
    font-weight: normal;
    font-size: 14px !important;
  }

  .p-fileupload-file-badge,
  .p-fileupload-filename {
    font-size: 12px;
  }
`;

export const ModalSharePoint = styled.div`
  padding: 0 2rem 2rem 1.5rem;

  .input-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 15px;
    margin-bottom: 1rem;

    div {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .p-inputtext {
    width: 70%;
  }

  .p-invalid {
    border-color: ${({ theme }) => theme.color_critical};
  }

  .button-container {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 4rem;
    gap: 15px;
    flex-direction: row-reverse;
  }
`;

export const ModalHeaderSharePoint = styled.a`
  background: url(${docConnector}) no-repeat -35px -20px #6f75ca;
  width: 100%;
  min-width: 619px;
  height: 87px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .caret-sharepoint-modal {
    color: white;
    position: absolute;
    right: 40px;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title-sharepoint-modal,
  .subtitle-sharepoint-modal {
    color: ${({ theme }) => theme.color_white};
    padding-left: 140px;
    width: 70%;
  }

  .title-sharepoint-modal {
    font-size: 18px;
    font-weight: bold;
  }

  .subtitle-sharepoint-modal {
    font-size: 12px;
  }
`;

export const SiteIds = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;

  .input-wrapper {
    display: flex;
    justify-content: flex-end;

    .p-inputtext {
      width: 60%;
    }

    width: 100%;
    margin: 0;
  }

  .wrapper-siteid-select {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex: 2;
    gap: 15px;
    width: 70%;

    .multi-select-siteid {
      width: 400px;
      flex-grow: 0;
    }
  }

  .label-siteid-input {
    font-size: 16px;
    width: 78px;
    font-weight: bold;
  }
`;

export const StepperRagWrapper = styled.div`
  position: fixed;
  display: flex;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  padding: 20px 30px;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px -10px 10px #152d620d;
  background-color: ${({ theme }) => theme.color_white};

  .stepper-rag-actions {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
  }

  .inner-content {
    display: flex;
    width: 100%;
    flex-direction: row;

    &:first-child {
      Button {
        margin-right: 15px;
      }
    }
  }

  .step {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 50px;
    font-size: 16px;

    .label {
      font-size: 14px;
      color: ${({ theme }) => theme.color_grey__400};
      transition: all ease 0.5s;
    }
  }

  .p-tag {
    font-size: 12px;
  }
`;

export const ModalFilesUploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .content-label {
    color: ${({ theme }) => theme.color_grey__500};
    font-size: 14px;
    font-style: italic;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .p-panel-title {
    color: rgb(70, 82, 95);
    font-size: 22px;
    font-weight: bold;
  }

  .cancel-button {
    display: none;
  }

  .p-fileupload-buttonbar {
    display: flex;
    justify-content: space-between;
    border: none;
    border-radius: 6px;
    background: ${({ theme }) => theme.color_white};

    & > :nth-child(1) {
      order: 2;
    }

    & > :nth-child(2) {
      order: 3;
    }

    & > :nth-child(3) {
      order: 1;
    }
  }

  .p-fileupload-file-badge {
    display: none;
  }

  .p-panel-subtitle {
    color: rgb(116, 136, 157);
    font-size: 14px;
  }

  .p-fileupload {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
  }

  .p-fileupload-content {
    width: 100%;
    white-space: nowrap;
    height: 100%;
  }
`;

export const EmptyFilesWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color_grey__400};
  background: #ebf5f7;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  transition: all ease 0.5s;

  &:hover {
    box-shadow: 0px 2px 4px #5060704d;
    border-color: #7091d8;
  }

  .subtitle {
    margin-bottom: 10px;
  }

  .inner-content {
    display: flex;
    flex-direction: row-reverse;
    align-items: start;
    justify-content: space-between;
    width: 100%;
    margin: 20px 0 0 20px;

    .title {
      font-size: 24px;
      color: ${({ theme }) => theme.color_grey__600};
      margin-bottom: 15px;
    }

    img {
      margin: 0 0 15px 0;
    }

    ul {
      padding: 0;

      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
        font-size: 14px;
        color: ${({ theme }) => theme.color_grey__600};

        .text {
          margin-left: 5px;

          &.disabled {
            color: ${({ theme }) => theme.color_grey__500};
          }
        }
      }
    }

    .arrow-right,
    .upload {
      display: flex;
      align-self: flex-end;
    }
  }
`;

export const EmptySharePointWrapper = styled(EmptyFilesWrapper)`
  background: #f0fafa;
`;

export const EmptyUrlWrapper = styled(EmptyFilesWrapper)`
  background: #ebf0f7;
`;

export const ButtonAdd = styled(Button)`
  margin-top: 18px;
`;

export const FormAddUrl = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;

  .p-checkbox {
    background-color: red;
  }

  .p-invalid {
    border-color: ${({ theme }) => theme.color_critical};
  }

  div:first-child {
    flex: 2 1 0;
  }

  div:nth-child(2) {
    flex: 4 1 0;
  }

  button:last-child {
    flex: 1 1 0;
  }
`;

export const ModalUrls = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;

  .urls-options {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  .button-add-website {
    width: 30%;
    align-self: flex-end;
  }
`;

export const MessageAlignLeft = styled(Message)`
  justify-content: left;
`;

export const UrlListContainer = styled.ul`
  max-height: 21.9vh;
  overflow-y: auto;
  border-radius: 5px;
  margin-top: 1rem;
  list-style-type: none;
  font-size: 14px;
  padding: 0;

  .p-button-icon {
    margin: 0;
  }

  .p-button {
    &:focus {
      box-shadow: none;
    }
  }

  li {
    justify-content: space-between;
    align-items: center;
    padding: 0.2rem 0.5rem;
    border-bottom: 1px solid #e0e0e0;
    transition: background-color 0.2s ease;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: #d9d9d9;
    }
  }
`;
