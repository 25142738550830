import { MutableRefObject } from 'react';

export const groupByKey = (list, key) =>
  list?.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {});

export const convertObjectToKeyValueArray = (obj: any, refId?: MutableRefObject<number>) => {
  if (obj) {
    return Object.entries(obj).map(([key, value]) => {
      const keyValue: KeyValue = {
        id: refId && refId ? refId.current++ : undefined,
        key: key,
        value: value ? String(value) : '',
      };
      return keyValue;
    });
  } else {
    return [];
  }
};
