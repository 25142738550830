import { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentBot } from '../Redux/Bot/selectors';
import {
  getAvailableLLMs,
  getCustomLLMForBot,
  getDefaultLLM,
  getToken,
  removeCustomLLMConfiguration,
  updateCustomLLMConfiguration,
} from '../Api/Tools/Api';
import { useUserContext } from './UserContext';
import { APP_PATH } from '../Tools/Constants';
import { toastError, toastSuccess } from '../Tools/Toast';
import { useTranslation } from 'react-i18next';

interface CustomLLMProviderProps {
  customLLM: API_BACKEND.Schemas.LLMCustomBotConfigurationDTO | null;
  defaultCustomLLM: API_BACKEND.Schemas.LLMCustomBotConfigurationDTO | null;
  defaultLLMType: API_BACKEND.Schemas.AvailableLLMResponse | undefined;
  updateCustomLLM: (
    llmType:
      | 'DEFAULT_GPT_4O_AZURE_OPENAI'
      | 'OPENAI'
      | 'AZURE_OPENAI'
      | 'MISTRAL'
      | 'GOOGLEAI_GEMINI'
      | 'VERTEXAI_GEMINI'
      | 'SCALEWAY',
    apiKey: string,
    model: string,
    embedModel: string,
    specificConfiguration: API_BACKEND.Schemas.LLMCustomBotConfigurationSpecificConfiguration | undefined
  ) => Promise<any>;
  removeCustomLLM: () => void;
  setLoading: (loading: boolean) => void;
  loading: boolean;
  getLlmTypeConfig: (
    llmType:
      | 'DEFAULT_GPT_4O_AZURE_OPENAI'
      | 'OPENAI'
      | 'AZURE_OPENAI'
      | 'MISTRAL'
      | 'GOOGLEAI_GEMINI'
      | 'VERTEXAI_GEMINI'
      | 'SCALEWAY'
      | undefined
  ) => API_BACKEND.Schemas.AvailableLLMResponse | undefined;
  availableLLMs: API_BACKEND.Schemas.AvailableLLMResponse[];
}

export const useCustomLLM = () => useContext<CustomLLMProviderProps>(CustomLLMContext);
export const CustomLLMContext = createContext({} as CustomLLMProviderProps);

const CustomLLMProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();

  const [customLLM, setCustomLLM] = useState<API_BACKEND.Schemas.LLMCustomBotConfigurationDTO | null>(null);
  const [defaultCustomLLM, setDefaultCustomLLM] = useState<API_BACKEND.Schemas.LLMCustomBotConfigurationDTO | null>(
    null
  );
  const [defaultLLMType, setDefaultLLMType] = useState<API_BACKEND.Schemas.AvailableLLMResponse>();
  const [availableLLMs, setAvailableLLMs] = useState<API_BACKEND.Schemas.AvailableLLMResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const botUUID = useSelector(getCurrentBot)?.botUUID;
  const { logout } = useUserContext();

  useEffect(() => {
    if (botUUID && window.location.href?.includes(APP_PATH.EXTERNAL_CONTENTS)) {
      setLoading(true);
      getAvailableLLMConfigurations(getToken()).then(() => {
        getCustomLLMForBot(botUUID, getToken())
          .then((customLLM: API_BACKEND.Schemas.LLMCustomBotConfigurationDTO) => {
            setCustomLLM(customLLM);
          })
          .then(() => {
            getDefaultLLMConfigurations(getToken()).then(() => {
              setLoading(false);
            });
          })
          .catch((error: any) => {
            if (error?.response?.status == '401') {
              console.log(error);
              logout();
            } else {
              console.log(error);
            }
          });
      });
    }
  }, [botUUID]);

  const getAvailableLLMConfigurations = async (token: string) => {
    try {
      const availableLLMs = await getAvailableLLMs(token);
      setAvailableLLMs(availableLLMs);
      setDefaultLLMType(availableLLMs.find((item: API_BACKEND.Schemas.AvailableLLMResponse) => item.isDefault));
    } catch (error: any) {
      if (error?.response?.status == '401') {
        logout();
      }
      console.log(error);
    }
  };

  const getDefaultLLMConfigurations = async (token: string) => {
    try {
      const defaultCustomLLM = await getDefaultLLM(token);
      setDefaultCustomLLM(defaultCustomLLM);
    } catch (error: any) {
      if (error?.response?.status == '401') {
        logout();
      }
      console.log(error);
    }
  };

  const updateCustomLLM = async (
    llmType:
      | 'DEFAULT_GPT_4O_AZURE_OPENAI'
      | 'OPENAI'
      | 'AZURE_OPENAI'
      | 'MISTRAL'
      | 'GOOGLEAI_GEMINI'
      | 'VERTEXAI_GEMINI'
      | 'SCALEWAY',
    apiKey: string,
    model: string,
    embedModel: string,
    specificConfiguration: API_BACKEND.Schemas.LLMCustomBotConfigurationSpecificConfiguration | undefined = {}
  ) => {
    const newCustomLLM: API_BACKEND.Schemas.LLMCustomBotConfigurationDTO = {
      ...customLLM,
      llmType,
      apiKey,
      model,
      embedModel,
      specificConfiguration,
    };

    return updateCustomLLMConfiguration(botUUID, newCustomLLM, getToken())
      .then((res) => {
        setCustomLLM(res);
        toastSuccess(t('externalContents.customLLM.action.message.updated'));
      })
      .catch((error: any) => {
        if (error?.response?.status == '401') {
          console.log(error);
          logout();
        } else {
          toastError(t('externalContents.customLLM.action.message.updateError'));
        }
      });
  };

  const removeCustomLLM = () => {
    removeCustomLLMConfiguration(botUUID, getToken())
      .then(() => {
        setCustomLLM(defaultCustomLLM);
        toastSuccess(t('externalContents.customLLM.action.message.restore'));
      })
      .catch((error: any) => {
        if (error?.response?.status == '401') {
          console.log(error);
          logout();
        } else {
          toastError(t('externalContents.customLLM.action.message.restoreError'));
        }
      });
  };

  const getLlmTypeConfig = (
    llmType:
      | 'DEFAULT_GPT_4O_AZURE_OPENAI'
      | 'OPENAI'
      | 'AZURE_OPENAI'
      | 'MISTRAL'
      | 'GOOGLEAI_GEMINI'
      | 'VERTEXAI_GEMINI'
      | 'SCALEWAY'
      | undefined
  ) => {
    return availableLLMs.find((item) => item.name === llmType);
  };

  const props = {
    customLLM,
    defaultCustomLLM,
    defaultLLMType,
    updateCustomLLM,
    removeCustomLLM,
    setLoading,
    loading,
    getLlmTypeConfig,
    availableLLMs,
  };

  return <CustomLLMContext.Provider children={children} value={props} />;
};

export default CustomLLMProvider;
