import Ajv from 'ajv';

import broken2023_02_21 from './broken_configs/backend_edge_2023-02-21/backend_edge_2023-02-21';
import broken2023_05_16 from './broken_configs/backend_edge_2023-05-16/backend_edge_2023-05-16';
import broken2023_08_08 from './broken_configs/backend_edge_2023-08-08/backend_edge_2023-08-08';
import broken2023_08_22 from './broken_configs/backend_edge_2023-08-22/backend_edge_2023-08-22';
import broken2023_09_05 from './broken_configs/backend_edge_2023-10-03/backend_edge_2023-10-03';

import v2022_12_13 from './chatbox_edge_2022-12-13/chatbox_edge_2022-12-13';
import v2023_01_24 from './chatbox_edge_2023-01-24/chatbox_edge_2023-01-24';
import v2023_02_21 from './chatbox_edge_2023-02-21/chatbox_edge_2023-02-21';
import v2023_05_02 from './chatbox_edge_2023-05-02/chatbox_edge_2023-05-02';
import v2023_05_16 from './chatbox_edge_2023-05-16/chatbox_edge_2023-05-16';
import v2023_06_07 from './chatbox_edge_2023-06-13/chatbox_edge_2023-06-13';
import v2023_07_25 from './chatbox_edge_2023-07-25/chatbox_edge_2023-07-25';
import v2023_08_08 from './chatbox_edge_2023-08-08/chatbox_edge_2023-08-08';
import v2023_08_22 from './chatbox_edge_2023-08-22/chatbox_edge_2023-08-22';
import v2023_10_03 from './chatbox_edge_2023-10-03/chatbox_edge_2023-10-03';
import v2023_11_14 from './chatbox_edge_2023-11-14/chatbox_edge_2023-11-14';
import v2024_01_09 from './chatbox_edge_2024-01-09/chatbox_edge_2024-01-09';
import v2024_05_14 from './chatbox_edge_2024-05-14/chatbox_edge_2024-05-14';
import v2024_06_25 from './chatbox_edge_2024-06-25/chatbox_edge_2024-06-25';
import v2024_08_06 from './chatbox_edge_2024-08-06/chatbox_edge_2024-08-06';
import v2024_10_01 from './chatbox_edge_2024-10-01/chatbox_edge_2024-10-01';
import v2024_10_15 from './chatbox_edge_2024-10-15/chatbox_edge_2024-10-15';
import v2024_10_29 from './chatbox_edge_2024-10-29/chatbox_edge_2024-10-29';
import v2024_12_10 from './chatbox_edge_2024-12-10/chatbox_edge_2024-12-10';
import v2025_04_01 from './chatbox_edge_2025-04-01/chatbox_edge_2025-04-01';
import v2025_04_15 from './chatbox_edge_2025-04-15/chatbox_edge_2025-04-15';
import latest from './current/current';

import { BrokenRelease, Release } from '../../../../typings/states';

const orderedBrokenVersions: BrokenRelease[] = [
  broken2023_09_05,
  broken2023_08_22,
  broken2023_08_08,
  broken2023_05_16,
  broken2023_02_21,
];

const orderedVersions: Release[] = [
  latest,
  v2025_04_15,
  v2025_04_01,
  v2024_12_10,
  v2024_10_29,
  v2024_10_15,
  v2024_10_01,
  v2024_08_06,
  v2024_06_25,
  v2024_05_14,
  v2024_01_09,
  v2023_11_14,
  v2023_10_03,
  v2023_08_22,
  v2023_08_08,
  v2023_07_25,
  v2023_06_07,
  v2023_05_16,
  v2023_05_02,
  v2023_02_21,
  v2023_01_24,
  v2022_12_13,
];

const findJsonRelease = (json, getSchema): Release | undefined => {
  return orderedVersions.find((release: Release) => validateJsonWithSchema(json, getSchema(release)));
};

const findJsonBrokenRelease = (json, getSchema): BrokenRelease | undefined => {
  return orderedBrokenVersions.find((brokenRelease: BrokenRelease) =>
    validateJsonWithSchema(json, getSchema(brokenRelease))
  );
};

export const validateJsonWithSchema = (json, schema) => {
  if (!schema) {
    return false;
  }

  const ajv = new Ajv({ allErrors: true, strictTuples: false });
  const validate = ajv.compile(schema);
  return validate(json);
};

export const findContentJsonRelease = (json): Release | undefined => {
  return findJsonRelease(json, (release: Release) => release?.contentSchema);
};

export const findContentJsonBrokenRelease = (json): BrokenRelease | undefined => {
  return findJsonBrokenRelease(json, (release: BrokenRelease) => release?.brokenContentSchema);
};

export const findTranslationJsonRelease = (json): Release | undefined => {
  return findJsonRelease(json, (release: Release) => release?.translationSchema);
};

export const findTranslationJsonBrokenRelease = (json): BrokenRelease | undefined => {
  return findJsonBrokenRelease(json, (release: BrokenRelease) => release?.brokenTranslationSchema);
};

export const findThemeJsonRelease = (json): Release | undefined => {
  return findJsonRelease(json, (release: Release) => release?.themeSchema);
};
