import { InputCheckbox, InputRadio, InputText, InputTextarea, InputWrapper, Label } from './Style';

export interface InputProps {
  label?: string;
  value?: string;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  checked?: boolean;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  type?: 'text' | 'textarea' | 'number' | 'radio' | 'checkbox';
  maxLength?: number;
  invalid?: boolean;
  width?: string;
  unstyled?: boolean;
  dataTestId?: string;
}

const Input = ({
  type = 'text',
  label,
  onChange,
  onBlur,
  checked = false,
  placeholder,
  value,
  disabled,
  className = '',
  maxLength,
  invalid,
  width,
  unstyled,
  dataTestId,
}: InputProps) => {
  const props = {
    type,
    label,
    onChange,
    placeholder,
    onBlur,
    value,
    checked,
    className,
    disabled,
    maxLength,
    invalid,
    width,
    unstyled,
    dataTestId,
  };

  const renderInput = () => {
    switch (type) {
      case 'text':
        return <InputText {...props} />;
      case 'textarea':
        return <InputTextarea {...props} />;
      case 'radio':
        return <InputRadio {...props} />;
      case 'checkbox':
        return <InputCheckbox {...props} />;
      default:
        return <></>;
    }
  };

  const renderLabel = () => label && <Label>{label}</Label>;

  return (
    <InputWrapper className={`input-wrapper ${type}`}>
      {renderLabel()}
      {renderInput()}
    </InputWrapper>
  );
};

export default Input;
