import * as Types from './types';

import { produce } from 'immer';
import { Configurations } from '../../../typings/states';

const initialState: Configurations = {
  list: [],
  selected: null,
  messageError: null,
  imagesData: null,
};

const ConfigurationsReducer = (state = initialState, { type, payload }: Redux.Action): Configurations =>
  produce(state, (draft) => {
    switch (type) {
      case Types.CONFIGURATIONS.GET_ALL.SUCCESS:
        draft.list = payload.data;
        break;
      case Types.CONFIGURATIONS.GET_ALL.FAILURE: {
        const { error } = payload;
        draft.messageError = error?.response?.data?.message || error?.message;
        break;
      }

      case Types.WEBSITE_CONFIGURATION.GET_ONE.REQUEST:
      case Types.CONNECTOR_CONFIGURATION.GET_ONE.REQUEST:
        draft.selected = null;
        draft.messageError = null;
        break;

      case Types.WEBSITE_CONFIGURATION.GET_ONE.SUCCESS:
      case Types.CONNECTOR_CONFIGURATION.GET_ONE.SUCCESS:
      case Types.META_CONFIGURATION.CREDENTIALS.SUCCESS:
      case Types.SET_SELECTED_CONFIGURATION:
        draft.selected = payload.data;
        break;

      case Types.WEBSITE_CONFIGURATION.GET_ONE.FAILURE: {
        const { error } = payload;
        draft.messageError = error?.response?.data?.message || error?.message;
        break;
      }
      case Types.CONNECTOR_CONFIGURATION.UPDATE_ONE.FAILURE: {
        const { error } = payload;
        draft.messageError = error?.response?.data?.message || error?.message;
        break;
      }
      case Types.CONNECTOR_CONFIGURATION.GET_ONE.FAILURE: {
        const { error } = payload;
        draft.messageError = error?.response?.data?.message || error?.message;
        break;
      }

      case Types.META_CONFIGURATION.WEBHOOK.SUCCESS:
        if (payload.data) {
          draft.selected.info.appInfo.webhookSubscriptions = payload.data;
        }
        break;

      case Types.META_LINKED_OBJECT.CREATE_ONE.SUCCESS: {
        const { data } = payload;
        draft.selected.info.linkedObjects = [...state.selected.info.linkedObjects, data];
        break;
      }
      case Types.WEBSITE_CONFIGURATION.UPDATE_ONE.FAILURE: {
        const { error } = payload;
        draft.messageError = error?.response?.data?.message || error?.message;
        break;
      }
      case Types.META_LINKED_OBJECT.UPDATE_ONE.SUCCESS: {
        const { data } = payload;
        draft.selected.info.linkedObjects = state.selected.info.linkedObjects.map((object) =>
          object.id === data.id ? data : object
        );
        break;
      }

      case Types.META_LINKED_OBJECT.DELETE_ONE.SUCCESS: {
        const { id } = payload;
        draft.selected.info.linkedObjects = state.selected.info.linkedObjects.filter((object) => object.id !== id);
        break;
      }

      case Types.WEBSITE_CONFIGURATION.CREATE_ONE.SUCCESS:
      case Types.WEBSITE_CONFIGURATION.CREATE_ONE.FAILURE: {
        const { error } = payload;
        draft.messageError = error?.response?.data?.message || error?.message;
        break;
      }
      case Types.CONNECTOR_CONFIGURATION.CREATE_ONE.SUCCESS:
      case Types.CONNECTOR_CONFIGURATION.CREATE_ONE.FAILURE: {
        const { error } = payload;
        draft.messageError = error?.response?.data?.message || error?.message;
        break;
      }
      case Types.WEBSITE_CONFIGURATION.IMPORT_ONE.SUCCESS: {
        const { data } = payload;
        const filteredList = state.list.filter((config) => config.id !== data.id);
        draft.list = [data, ...filteredList];
        break;
      }
      case Types.WEBSITE_CONFIGURATION.IMPORT_ONE.FAILURE: {
        const { error } = payload;
        draft.messageError = error?.response?.data?.message || error?.message;
        break;
      }
      case Types.WEBSITE_CONFIGURATION.DELETE_ONE.FAILURE: {
        const { error } = payload;
        draft.messageError = error?.response?.data?.message || error?.message;
        break;
      }
      case Types.WEBSITE_CONFIGURATION.DELETE_ONE.SUCCESS:
      case Types.CONNECTOR_CONFIGURATION.DELETE_ONE.SUCCESS:
        draft.list = state.list?.filter((config) => config.id !== payload.configId);
        break;

      case Types.CONNECTOR_CONFIGURATION.DELETE_ONE.FAILURE: {
        const { error } = payload;
        draft.messageError = error?.response?.data?.message || error?.message;
        break;
      }
      case Types.WEBSITE_CONFIGURATION.UPDATE_ONE.SUCCESS: {
        draft.selected = payload.data;
        break;
      }

      case Types.WEBSITE_CONFIGURATION.PUBLISH_ONE.SUCCESS:
      case Types.WEBSITE_CONFIGURATION.RESTORE_ONE.SUCCESS: {
        const { config, configList } = payload;
        draft.selected = config || state.selected;
        draft.list = configList || state.list;
        break;
      }

      case Types.WEBSITE_CONFIGURATION.UPDATE_NAME.SUCCESS:
      case Types.WEBSITE_CONFIGURATION.UPDATE_NAME.FAILURE: {
        const { error } = payload;
        draft.messageError = error?.response?.data?.message || error?.message;
        break;
      }
      case Types.WEBSITE_CONFIGURATION.UPDATE_SLUG.SUCCESS:
      case Types.WEBSITE_CONFIGURATION.UPDATE_SLUG.FAILURE: {
        const { error } = payload;
        draft.messageError = error?.response?.data?.message || error?.message;
        break;
      }
      case Types.CONNECTOR_CONFIGURATION.UPDATE_NAME.SUCCESS: {
        const { data } = payload;
        draft.list = state.list.map((config) => (config.id === data.id ? data : config));
        break;
      }
      case Types.CONNECTOR_CONFIGURATION.UPDATE_NAME.FAILURE: {
        const { error } = payload;
        draft.messageError = error?.response?.data?.message || error?.message;
        break;
      }

      case Types.WEBSITE_LOCAL_STORAGE.UPDATE_IMAGES_DATA.SUCCESS:
        draft.imagesData = payload.data;
        break;

      case Types.WEBSITE_LOCAL_STORAGE.UPDATE_CONTENT.SUCCESS:
        draft.selected.content = payload.data;
        break;

      default:
        return draft;
    }
  });

export default ConfigurationsReducer;
