import { APP_PATH, CONFIG_TYPES } from 'src/Tools/Constants';
import { Config, ConfigsTypes, MainContainer } from './Style';
import { ConfigProps, NewConfigContainerProps } from './NewConfigContainer';

import Button from 'src/Components/Button/Button';
import Chip from 'src/Components/Chip/Chip';
import CreateConfigContainer from 'src/Modals/CreateConfig/CreateConfigContainer';
import Heading from 'src/Components/Heading/Heading';
import Icon from 'src/Components/Icon/Icon';
import { upercaseFirstLetter } from 'src/Tools/Text';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const NewConfig = ({ openModal, configList }: NewConfigContainerProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleCreateConfig = (type: string) => {
    const modalType = type === CONFIG_TYPES.WEBSITE ? t('newConfig.config.website.title') : type;
    return openModal(
      <CreateConfigContainer forcedMode={CONFIG_TYPES.WEBSITE !== type ? 'new' : null} type={type} />,
      t('newConfig.modal.title', { configType: upercaseFirstLetter(modalType) })
    );
  };

  const renderBackButton = () => (
    <Button
      label={t('general.previous')}
      onClick={() => navigate(APP_PATH.CONFIGURATIONS)}
      className='p-button-text p-button-secondary'
      icon='icon-caret-left'
    />
  );

  const renderConfig = (
    type,
    { title, subtitle, icon, integrationList, isNew, isFeatured, disabled, comment }: ConfigProps
  ) => (
    <Config
      className={type}
      data-testid={type}
      disabled={disabled}
      onClick={() => !disabled && handleCreateConfig(type)}
    >
      {isNew && <Chip className='new' label={t('general.new')} />}
      {isFeatured && (
        <div className='featured'>
          <Icon icon='star' color='white' />
        </div>
      )}
      <div>
        <div className='logo'>
          <img src={icon} />
        </div>
        <div className='title'>{title}</div>
        <div className='subtitle'>{subtitle}</div>
        {comment && <div className='list'>{comment}</div>}
        {integrationList && (
          <ul className='list'>
            {integrationList.map((i) => (
              <li key={i}>• {i}</li>
            ))}
          </ul>
        )}
      </div>
      <div className='action'>
        <Icon icon='arrow-right' />
      </div>
    </Config>
  );

  return (
    <>
      <MainContainer>
        <div>
          <Heading as='h1' className='title' title={t('newConfig.heading.title')} />
          <Heading as='h5' className='subtitle' title={t('newConfig.heading.subtitle')} />
        </div>
        <div>
          <ConfigsTypes>
            <div className='column'>{renderConfig(CONFIG_TYPES.WEBSITE, configList[CONFIG_TYPES.WEBSITE])}</div>
            <div className='column'>
              {renderConfig(CONFIG_TYPES.TEAMS, configList[CONFIG_TYPES.TEAMS])}
              {/* {renderConfig(CONFIG_TYPES.SLACK, configList[CONFIG_TYPES.SLACK])} */}
            </div>
            <div className='column'>{renderConfig(CONFIG_TYPES.META, configList[CONFIG_TYPES.META])}</div>
            <div className='column'>{renderConfig(CONFIG_TYPES.CALLBOT, configList[CONFIG_TYPES.CALLBOT])}</div>
          </ConfigsTypes>
        </div>
        <div>{renderBackButton()}</div>
      </MainContainer>
    </>
  );
};

export default NewConfig;
