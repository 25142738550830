import styled from 'styled-components';
import { Card } from 'primereact/card';

export const CardLlmConfiguration = styled(Card)<{
  $marginLeft?: string | number;
  $marginRight?: string | number;
}>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: ${(props) => props.$marginRight || 0};
  margin-left: ${(props) => props.$marginLeft || 0};
`;

export const CardLlmConfigurationDescription = styled.p<{
  $height?: string | number;
}>`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  height: ${(props) => props.$height || '100%'};
  padding-bottom: 20px;
`;

export const SliderBottomLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2px;
`;
