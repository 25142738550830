import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import {
  CardTitleContainer,
  CreateWrapper,
  CustomLLMCard,
  CustomLLMCardFixedWidth,
  CustomLLMCardWithScroll,
  CustomLLMColumn,
  FormWithMarginBottom,
  HorizontalGroup,
  LlmDropdownItemTemplate,
  LlmDropdownTemplate,
  LlmTypeDropdown,
  SpecificConfigurationHorizontalGroup,
  Step,
} from '../../Style';
import { APP_PATH } from '../../../../Tools/Constants';
import useDocumentTitle from '../../../../Hooks/useDocumentTitle';
import { Button } from 'primereact/button';
import Input from '../../../../Components/Input/Input';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Skeleton } from 'primereact/skeleton';
import { useCustomLLM } from '../../../../Contexts/CustomLLMContext';
import azureOpenAIDydu from '../../../../Assets/Images/llm/azureopenai_dydu.png';
import azureOpenAI from '../../../../Assets/Images/llm/azureopenai.png';
import openAI from '../../../../Assets/Images/llm/openai.png';
import mistralAI from '../../../../Assets/Images/llm/mistralai.png';
import googleAIGemini from '../../../../Assets/Images/llm/googleai_gemini.png';
import vertexAIGemini from '../../../../Assets/Images/llm/vertexai_gemini.png';
import scaleway from '../../../../Assets/Images/llm/scaleway.png';
import ModalIdentityProviderConfiguration from './ModalIdentityProviderConfiguration';
import { buildCurlRequestFromData } from '../../../../Tools/Curl';
import { convertObjectToKeyValueArray } from '../../../../Tools/Array';
import { setNext } from '../../../../Api/Tools/Api';
import { toastError } from '../../../../Tools/Toast';

const CustomLLM = () => {
  setNext(APP_PATH.EXTERNAL_CONTENTS);
  const { t } = useTranslation();
  const {
    customLLM,
    defaultCustomLLM,
    defaultLLMType,
    updateCustomLLM,
    removeCustomLLM,
    getLlmTypeConfig,
    availableLLMs,
    loading,
  } = useCustomLLM();

  const [isConfigureIdp, setIsConfigureIdp] = useState<boolean>(false);

  const [selectedLlmTypeConfig, setSelectedLlmTypeConfig] = useState<
    API_BACKEND.Schemas.AvailableLLMResponse | undefined
  >(getLlmTypeConfig(customLLM?.llmType) || defaultLLMType);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [llmType, setLlmType] = useState<
    | 'DEFAULT_GPT_4O_AZURE_OPENAI'
    | 'OPENAI'
    | 'AZURE_OPENAI'
    | 'MISTRAL'
    | 'GOOGLEAI_GEMINI'
    | 'VERTEXAI_GEMINI'
    | 'SCALEWAY'
  >(defaultLLMType?.name || 'DEFAULT_GPT_4O_AZURE_OPENAI');
  const [apiKey, setApiKey] = useState<string>('');
  const [model, setModel] = useState<string>('');
  const [embedModel, setEmbedModel] = useState<string>('');

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [isInvalidAuthentication, setIsInvalidAuthentication] = useState(false);
  const [isInvalidModel, setIsInvalidModel] = useState(false);
  const [isInvalidEmbedModel, setIsInvalidEmbedModel] = useState(false);
  const [isInvalidSpecificConfiguration, setIsInvalidSpecificConfiguration] = useState(false);

  const [specificConfiguration, setSpecificConfiguration] =
    useState<API_BACKEND.Schemas.LLMCustomBotConfigurationSpecificConfiguration>();

  const EMPTY_SPECIFIC_CONFIG: API_BACKEND.Schemas.LLMCustomBotConfigurationSpecificConfiguration = {
    apiVersion: '',
    azureEndpoint: '',
    azureDeployment: '',
    projectNumber: '',
    location: '',
    identityPoolId: '',
    providerName: '',
    serviceAccount: '',
    tokenEndpoint: '',
    idpHeaders: [],
    idpData: [],
    scalewayEndpoint: '',
  };

  useDocumentTitle(`${t('general.pages.externalContents')}`);

  const resetValidation = () => {
    setIsInvalidAuthentication(false);
    setIsInvalidModel(false);
    setIsInvalidEmbedModel(false);
    setIsInvalidSpecificConfiguration(false);
  };

  const resetForm = () => {
    setIsEditing(false);
    setLlmType(customLLM?.llmType || defaultLLMType?.name || 'DEFAULT_GPT_4O_AZURE_OPENAI');
    setApiKey(customLLM?.apiKey || '');
    setModel(customLLM?.model || '');
    setEmbedModel(customLLM?.embedModel || '');
    setSpecificConfiguration(customLLM?.specificConfiguration || EMPTY_SPECIFIC_CONFIG);
    setSelectedLlmTypeConfig(getLlmTypeConfig(customLLM?.llmType));

    resetValidation();
  };

  /**
   * Called when config is loaded, saved or restored
   */
  useEffect(() => {
    resetForm();
  }, [customLLM]);

  const submitForm = () => {
    resetValidation();

    if (checkIfAuthenticationIsInvalid() || checkIfModelsAreInvalid() || checkIfSpecificConfigurationIsInvalid()) {
      return;
    }

    setIsSaving(true);
    updateCustomLLM(llmType, apiKey, model, embedModel, specificConfiguration).then(() => setIsSaving(false));
  };

  const checkIfAuthenticationIsInvalid = (): boolean => {
    const apiKeyLlmTypes = ['AZURE_OPENAI', 'OPENAI', 'MISTRAL', 'GOOGLEAI_GEMINI', 'SCALEWAY'];
    const idpLlmTypes = ['VERTEXAI_GEMINI'];

    if (apiKeyLlmTypes.includes(llmType) && isValueEmpty(apiKey)) {
      setIsInvalidAuthentication(true);
      toastError(t('externalContents.customLLM.action.validationError.apiKey'));
      return true;
    } else if (idpLlmTypes.includes(llmType) && isValueEmpty(specificConfiguration?.tokenEndpoint)) {
      setIsInvalidAuthentication(true);
      toastError(t('externalContents.customLLM.action.validationError.idp'));
      return true;
    }

    return false;
  };

  const checkIfModelsAreInvalid = (): boolean => {
    let isInvalid = false;
    if (isValueEmpty(model)) {
      setIsInvalidModel(true);
      toastError(t('externalContents.customLLM.action.validationError.model'));
      isInvalid = true;
    }

    if (isValueEmpty(embedModel)) {
      setIsInvalidEmbedModel(true);
      toastError(t('externalContents.customLLM.action.validationError.embedModel'));
      isInvalid = true;
    }

    return isInvalid;
  };

  const checkIfSpecificConfigurationIsInvalid = (): boolean => {
    let isInvalid = false;

    isInvalid =
      isInvalid ||
      (llmType === 'AZURE_OPENAI' &&
        (isValueEmpty(specificConfiguration?.azureEndpoint) ||
          isValueEmpty(specificConfiguration?.azureDeployment) ||
          isValueEmpty(specificConfiguration?.apiVersion)));
    isInvalid = isInvalid || (llmType === 'OPENAI' && isValueEmpty(specificConfiguration?.apiVersion));
    isInvalid = isInvalid || (llmType === 'SCALEWAY' && isValueEmpty(specificConfiguration?.scalewayEndpoint));
    isInvalid =
      isInvalid ||
      (llmType === 'VERTEXAI_GEMINI' &&
        (isValueEmpty(specificConfiguration?.projectNumber) ||
          isValueEmpty(specificConfiguration?.location) ||
          isValueEmpty(specificConfiguration?.identityPoolId) ||
          isValueEmpty(specificConfiguration?.providerName) ||
          isValueEmpty(specificConfiguration?.serviceAccount)));

    if (isInvalid) {
      setIsInvalidSpecificConfiguration(true);
      toastError(t('externalContents.customLLM.action.validationError.specificConfiguration'));
      return true;
    }

    return false;
  };

  const saveIdpProviderData = (tokenEndpoint: string, idpHeaders: KeyValue[], idpParameters: KeyValue[]) => {
    setSpecificConfiguration({
      ...specificConfiguration,
      tokenEndpoint: tokenEndpoint,
      idpHeaders: Object.fromEntries(idpHeaders.map((item: KeyValue) => [item.key, item.value])),
      idpData: Object.fromEntries(idpParameters.map((item: KeyValue) => [item.key, item.value])),
    });
  };

  const isValueEmpty = (value?: string): boolean => {
    return !value || value.trim() === '';
  };

  const getImageFromLlmType = (
    llmType:
      | 'DEFAULT_GPT_4O_AZURE_OPENAI'
      | 'OPENAI'
      | 'AZURE_OPENAI'
      | 'MISTRAL'
      | 'GOOGLEAI_GEMINI'
      | 'VERTEXAI_GEMINI'
      | 'SCALEWAY'
  ) => {
    switch (llmType) {
      case 'DEFAULT_GPT_4O_AZURE_OPENAI':
        return azureOpenAIDydu;
      case 'AZURE_OPENAI':
        return azureOpenAI;
      case 'OPENAI':
        return openAI;
      case 'MISTRAL':
        return mistralAI;
      case 'GOOGLEAI_GEMINI':
        return googleAIGemini;
      case 'VERTEXAI_GEMINI':
        return vertexAIGemini;
      case 'SCALEWAY':
        return scaleway;
      default:
        return azureOpenAIDydu;
    }
  };

  const getAltImageFromLlmType = (
    llmType:
      | 'DEFAULT_GPT_4O_AZURE_OPENAI'
      | 'OPENAI'
      | 'AZURE_OPENAI'
      | 'MISTRAL'
      | 'GOOGLEAI_GEMINI'
      | 'VERTEXAI_GEMINI'
      | 'SCALEWAY'
  ) => {
    switch (llmType) {
      case 'DEFAULT_GPT_4O_AZURE_OPENAI':
        return t('externalContents.customLLM.type.azureOpenAIDydu');
      case 'AZURE_OPENAI':
        return t('externalContents.customLLM.type.azureOpenAI');
      case 'OPENAI':
        return t('externalContents.customLLM.type.openAI');
      case 'MISTRAL':
        return t('externalContents.customLLM.type.mistralAI');
      case 'GOOGLEAI_GEMINI':
        return t('externalContents.customLLM.type.googleAIGemini');
      case 'VERTEXAI_GEMINI':
        return t('externalContents.customLLM.type.vertexAIGemini');
      case 'SCALEWAY':
        return t('externalContents.customLLM.type.scaleway');
      default:
        return t('externalContents.customLLM.type.azureOpenAIDydu');
    }
  };

  const llmTypeTemplate = (
    option:
      | 'DEFAULT_GPT_4O_AZURE_OPENAI'
      | 'OPENAI'
      | 'AZURE_OPENAI'
      | 'MISTRAL'
      | 'GOOGLEAI_GEMINI'
      | 'VERTEXAI_GEMINI'
      | 'SCALEWAY'
  ) => {
    const image = getImageFromLlmType(option);
    const alt = getAltImageFromLlmType(option);
    if (image) {
      return <LlmDropdownTemplate src={image} alt={alt} />;
    }
    return option;
  };

  const llmTypeItemTemplate = (
    option:
      | 'DEFAULT_GPT_4O_AZURE_OPENAI'
      | 'OPENAI'
      | 'AZURE_OPENAI'
      | 'MISTRAL'
      | 'GOOGLEAI_GEMINI'
      | 'VERTEXAI_GEMINI'
      | 'SCALEWAY'
  ) => {
    const image = getImageFromLlmType(option);
    const alt = getAltImageFromLlmType(option);
    if (image) {
      return <LlmDropdownItemTemplate src={image} alt={alt} />;
    }
    return option;
  };

  const renderSpecificConfigurationCommon = (title, renderFunction) => {
    return (
      <CustomLLMCardWithScroll title={title}>
        {isEditing ? <Step>4</Step> : null}
        <ScrollPanel>{renderFunction()}</ScrollPanel>
      </CustomLLMCardWithScroll>
    );
  };

  const renderSpecificConfiguration = () => {
    if (llmType === 'OPENAI') {
      return renderSpecificConfigurationCommon(
        t('externalContents.customLLM.specificConfiguration.title'),
        renderSpecificConfigurationOpenAI
      );
    }

    if (llmType === 'SCALEWAY') {
      return renderSpecificConfigurationCommon(
        t('externalContents.customLLM.specificConfiguration.title'),
        renderSpecificConfigurationScaleway
      );
    }

    if (llmType === 'AZURE_OPENAI' || llmType === 'DEFAULT_GPT_4O_AZURE_OPENAI') {
      return renderSpecificConfigurationCommon(
        t('externalContents.customLLM.specificConfiguration.title'),
        renderSpecificConfigurationAzureOpenAI
      );
    }

    if (llmType === 'VERTEXAI_GEMINI') {
      return renderSpecificConfigurationCommon(
        t('externalContents.customLLM.specificConfiguration.vertexAI.title'),
        renderSpecificConfigurationVertexAI
      );
    }

    return renderSpecificConfigurationCommon(t('externalContents.customLLM.specificConfiguration.title'), () => {
      return <p className={'no-option'}>{t('externalContents.customLLM.specificConfiguration.notConcerned')}</p>;
    });
  };

  const renderSpecificConfigurationOpenAI = () => {
    return (
      <SpecificConfigurationHorizontalGroup>
        <label htmlFor='apiVersion'>{t('externalContents.customLLM.specificConfiguration.openAI.apiVersion')} :</label>
        <Input
          value={specificConfiguration?.apiVersion}
          onChange={(e) =>
            setSpecificConfiguration({
              ...specificConfiguration,
              apiVersion: e.target.value,
            })
          }
          invalid={isInvalidSpecificConfiguration && isValueEmpty(specificConfiguration?.apiVersion)}
          disabled={!isEditing || !selectedLlmTypeConfig?.isCustomizable}
        />
      </SpecificConfigurationHorizontalGroup>
    );
  };

  const renderSpecificConfigurationScaleway = () => {
    return (
      <SpecificConfigurationHorizontalGroup>
        <label htmlFor='scalewayEndpoint'>
          {t('externalContents.customLLM.specificConfiguration.scaleway.endpoint')} :
        </label>
        <Input
          placeholder={t('externalContents.customLLM.specificConfiguration.scaleway.placeholder')}
          value={specificConfiguration?.scalewayEndpoint}
          onChange={(e) =>
            setSpecificConfiguration({
              ...specificConfiguration,
              scalewayEndpoint: e.target.value,
            })
          }
          invalid={isInvalidSpecificConfiguration && isValueEmpty(specificConfiguration?.scalewayEndpoint)}
          disabled={!isEditing || !selectedLlmTypeConfig?.isCustomizable}
        />
      </SpecificConfigurationHorizontalGroup>
    );
  };

  const renderSpecificConfigurationAzureOpenAI = () => {
    return (
      <>
        <SpecificConfigurationHorizontalGroup>
          <label htmlFor='apiVersion'>
            {t('externalContents.customLLM.specificConfiguration.openAI.apiVersion')} :
          </label>
          <Input
            value={specificConfiguration?.apiVersion}
            onChange={(e) =>
              setSpecificConfiguration({
                ...specificConfiguration,
                apiVersion: e.target.value,
              })
            }
            invalid={isInvalidSpecificConfiguration && isValueEmpty(specificConfiguration?.apiVersion)}
            disabled={!isEditing || !selectedLlmTypeConfig?.isCustomizable}
            unstyled={!isEditing}
          />
        </SpecificConfigurationHorizontalGroup>
        <SpecificConfigurationHorizontalGroup>
          <label htmlFor='azureEndpoint'>
            {t('externalContents.customLLM.specificConfiguration.azureOpenAI.endpoint')} :
          </label>
          <Input
            value={specificConfiguration?.azureEndpoint}
            onChange={(e) =>
              setSpecificConfiguration({
                ...specificConfiguration,
                azureEndpoint: e.target.value,
              })
            }
            invalid={isInvalidSpecificConfiguration && isValueEmpty(specificConfiguration?.azureEndpoint)}
            disabled={!isEditing || !selectedLlmTypeConfig?.isCustomizable}
            unstyled={!isEditing}
          />
        </SpecificConfigurationHorizontalGroup>
        <SpecificConfigurationHorizontalGroup>
          <label htmlFor='azureDeployment'>
            {t('externalContents.customLLM.specificConfiguration.azureOpenAI.deploymentName')} :
          </label>
          <Input
            value={specificConfiguration?.azureDeployment}
            onChange={(e) =>
              setSpecificConfiguration({
                ...specificConfiguration,
                azureDeployment: e.target.value,
              })
            }
            invalid={isInvalidSpecificConfiguration && isValueEmpty(specificConfiguration?.azureDeployment)}
            disabled={!isEditing || !selectedLlmTypeConfig?.isCustomizable}
            unstyled={!isEditing}
          />
        </SpecificConfigurationHorizontalGroup>
      </>
    );
  };

  const renderSpecificConfigurationVertexAI = () => {
    return (
      <>
        <SpecificConfigurationHorizontalGroup>
          <label htmlFor='projectNumber'>
            {t('externalContents.customLLM.specificConfiguration.vertexAI.projectNumber.value')} :
          </label>
          <Input
            value={specificConfiguration?.projectNumber}
            onChange={(e) =>
              setSpecificConfiguration({
                ...specificConfiguration,
                projectNumber: e.target.value,
              })
            }
            invalid={isInvalidSpecificConfiguration && isValueEmpty(specificConfiguration?.projectNumber)}
            disabled={!isEditing || !selectedLlmTypeConfig?.isCustomizable}
            placeholder={isEditing ? '123456789010' : ''}
            unstyled={!isEditing}
          />
        </SpecificConfigurationHorizontalGroup>
        <SpecificConfigurationHorizontalGroup>
          <label htmlFor='location'>
            {t('externalContents.customLLM.specificConfiguration.vertexAI.location.value')} :
          </label>
          <Input
            value={specificConfiguration?.location}
            onChange={(e) =>
              setSpecificConfiguration({
                ...specificConfiguration,
                location: e.target.value,
              })
            }
            invalid={isInvalidSpecificConfiguration && isValueEmpty(specificConfiguration?.location)}
            disabled={!isEditing || !selectedLlmTypeConfig?.isCustomizable}
            placeholder={isEditing ? 'europe-west3' : ''}
            unstyled={!isEditing}
          />
        </SpecificConfigurationHorizontalGroup>
        <SpecificConfigurationHorizontalGroup>
          <label htmlFor='identityPoolId'>
            {t('externalContents.customLLM.specificConfiguration.vertexAI.identityPoolId.value')} :
          </label>
          <Input
            value={specificConfiguration?.identityPoolId}
            onChange={(e) =>
              setSpecificConfiguration({
                ...specificConfiguration,
                identityPoolId: e.target.value,
              })
            }
            invalid={isInvalidSpecificConfiguration && isValueEmpty(specificConfiguration?.identityPoolId)}
            disabled={!isEditing || !selectedLlmTypeConfig?.isCustomizable}
            unstyled={!isEditing}
          />
        </SpecificConfigurationHorizontalGroup>
        <SpecificConfigurationHorizontalGroup>
          <label htmlFor='providerName'>
            {t('externalContents.customLLM.specificConfiguration.vertexAI.providerName.value')} :
          </label>
          <Input
            value={specificConfiguration?.providerName}
            onChange={(e) =>
              setSpecificConfiguration({
                ...specificConfiguration,
                providerName: e.target.value,
              })
            }
            invalid={isInvalidSpecificConfiguration && isValueEmpty(specificConfiguration?.providerName)}
            disabled={!isEditing || !selectedLlmTypeConfig?.isCustomizable}
            unstyled={!isEditing}
          />
        </SpecificConfigurationHorizontalGroup>
        <SpecificConfigurationHorizontalGroup>
          <label htmlFor='serviceAccount'>
            {t('externalContents.customLLM.specificConfiguration.vertexAI.serviceAccount.value')} :
          </label>
          <Input
            value={specificConfiguration?.serviceAccount}
            onChange={(e) =>
              setSpecificConfiguration({
                ...specificConfiguration,
                serviceAccount: e.target.value,
              })
            }
            invalid={isInvalidSpecificConfiguration && isValueEmpty(specificConfiguration?.serviceAccount)}
            disabled={!isEditing || !selectedLlmTypeConfig?.isCustomizable}
            placeholder={
              isEditing ? t('externalContents.customLLM.specificConfiguration.vertexAI.serviceAccount.placeholder') : ''
            }
            unstyled={!isEditing}
          />
        </SpecificConfigurationHorizontalGroup>
      </>
    );
  };

  const getAvailableLLMsOptions = () => {
    return availableLLMs.map((availableLLM) => availableLLM.name);
  };

  const getSecondConfigBlockComponent = () => {
    switch (llmType) {
      case 'VERTEXAI_GEMINI':
        return (
          <CustomLLMCard title={t('externalContents.customLLM.identityProvider')}>
            {isEditing ? <Step>2</Step> : null}
            <Button
              label={specificConfiguration?.tokenEndpoint || t('general.configure')}
              icon='icon-option'
              onClick={(e) => {
                e.preventDefault();
                setIsConfigureIdp(true);
              }}
              className='btn-config p-button-text p-button-secondary'
              disabled={!isEditing}
              tooltip={buildCurlRequestFromData(
                specificConfiguration?.tokenEndpoint,
                convertObjectToKeyValueArray(specificConfiguration?.idpHeaders),
                convertObjectToKeyValueArray(specificConfiguration?.idpData)
              )}
            />
          </CustomLLMCard>
        );
      default:
        return (
          <CustomLLMCard title={t('externalContents.customLLM.authentication')}>
            {isEditing ? <Step>2</Step> : null}
            <Input
              value={apiKey}
              invalid={isInvalidAuthentication}
              onChange={(e) => setApiKey(e.target.value)}
              disabled={!isEditing || !selectedLlmTypeConfig?.isCustomizable}
              unstyled={!isEditing}
            />
          </CustomLLMCard>
        );
    }
  };

  const getThirdConfigBlockComponent = () => {
    return (
      <CustomLLMCard title={t('externalContents.customLLM.models')}>
        {isEditing ? <Step>3</Step> : null}
        <HorizontalGroup>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Input
              value={model}
              invalid={isInvalidModel}
              onChange={(e) => setModel(e.target.value)}
              disabled={!isEditing || !selectedLlmTypeConfig?.isCustomizable}
              unstyled={!isEditing}
            />
            <Input
              value={embedModel}
              invalid={isInvalidEmbedModel}
              onChange={(e) => setEmbedModel(e.target.value)}
              placeholder={llmType === 'GOOGLEAI_GEMINI' ? 'models/***' : ''}
              disabled={!isEditing || !selectedLlmTypeConfig?.isCustomizable}
              unstyled={!isEditing}
            />
          </div>
        </HorizontalGroup>
      </CustomLLMCard>
    );
  };

  return (
    <FormWithMarginBottom>
      {loading ? (
        <Skeleton width='1780px' height='390.5px' className='mb-2' borderRadius='16px'></Skeleton>
      ) : (
        <>
          <CreateWrapper>
            <div className='container-wrapper'>
              <CustomLLMColumn>
                <CardTitleContainer>
                  <h1>{t('externalContents.customLLM.title')}</h1>
                  {!loading && isEditing ? (
                    <div>
                      <Button
                        label={t('general.cancel')}
                        icon='icon-eraser'
                        onClick={(e) => {
                          e.preventDefault();
                          resetForm();
                        }}
                        className='mr-2 p-button-text p-button-secondary'
                      />
                      {selectedLlmTypeConfig?.isDefault ? (
                        <Button
                          label={t('externalContents.customLLM.action.restore')}
                          icon='icon-history'
                          onClick={(e) => {
                            e.preventDefault();
                            removeCustomLLM();
                          }}
                        />
                      ) : (
                        <Button
                          label={t('general.apply')}
                          icon='icon-check'
                          loading={isSaving}
                          disabled={!selectedLlmTypeConfig?.isCustomizable}
                          onClick={(e) => {
                            e.preventDefault();
                            submitForm();
                          }}
                        />
                      )}
                    </div>
                  ) : (
                    <Button label={t('general.modify')} icon='icon-pencil' onClick={() => setIsEditing(true)} />
                  )}
                </CardTitleContainer>
                <HorizontalGroup>
                  <CustomLLMColumn>
                    <HorizontalGroup className='width-30-percent'>
                      <CustomLLMCardFixedWidth title={t('externalContents.customLLM.modelType')} $width={'40%'}>
                        {isEditing ? <Step>1</Step> : null}
                        <LlmTypeDropdown
                          loading={!availableLLMs || availableLLMs.length === 0}
                          value={llmType}
                          onChange={(e) => {
                            setLlmType(e.value);
                            const llmTypeConfig = getLlmTypeConfig(e.value);
                            setSelectedLlmTypeConfig(llmTypeConfig);

                            if (llmTypeConfig?.name === 'DEFAULT_GPT_4O_AZURE_OPENAI') {
                              setApiKey(defaultCustomLLM?.apiKey || '');
                              setModel(defaultCustomLLM?.model || '');
                              setEmbedModel(defaultCustomLLM?.embedModel || '');
                              setSpecificConfiguration(defaultCustomLLM?.specificConfiguration);
                            } else {
                              setApiKey('');
                              setModel('');
                              setEmbedModel('');
                              setSpecificConfiguration(EMPTY_SPECIFIC_CONFIG);
                            }
                          }}
                          disabled={!isEditing}
                          options={getAvailableLLMsOptions()}
                          valueTemplate={llmTypeTemplate}
                          itemTemplate={llmTypeItemTemplate}
                          className='w-full md:w-14rem'
                        />
                      </CustomLLMCardFixedWidth>
                      {getSecondConfigBlockComponent()}
                    </HorizontalGroup>
                    {getThirdConfigBlockComponent()}
                  </CustomLLMColumn>
                  <CustomLLMColumn>{renderSpecificConfiguration()}</CustomLLMColumn>
                </HorizontalGroup>
              </CustomLLMColumn>
            </div>
          </CreateWrapper>
          <ModalIdentityProviderConfiguration
            visible={isConfigureIdp}
            onHide={() => setIsConfigureIdp(false)}
            specificConfiguration={specificConfiguration}
            saveIdpProviderData={saveIdpProviderData}
          />
        </>
      )}
    </FormWithMarginBottom>
  );
};

export default CustomLLM;
