import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import useDocumentTitle from '../../../../Hooks/useDocumentTitle';
import { APP_PATH } from '../../../../Tools/Constants';
import {
  CardSubTitleContainer,
  CardTitleContainer,
  CreateWrapper,
  CustomLLMColumn,
  FormWithMarginBottom,
  HorizontalGroup,
} from '../../Style';
import { Slider } from 'primereact/slider';
import { InputTextarea } from '../../../../Components/Input/Style';
import { Button } from 'primereact/button';
import { CardLlmConfiguration, CardLlmConfigurationDescription, SliderBottomLabel } from './Style';
import { setNext } from '../../../../Api/Tools/Api';
import { useLLMConfiguration } from '../../../../Contexts/LLMConfigurationContext';

const LLMConfiguration = () => {
  setNext(APP_PATH.EXTERNAL_CONTENTS);
  const { t } = useTranslation();

  const { llmConfigurationRag, saveLLMConfigurationRAG } = useLLMConfiguration();

  const [temperature, setTemperature] = useState(0.1);
  const [maxOutputTokens, setMaxOutputTokens] = useState(200);
  const [answerSourceMinScore, setAnswerSourceMinScore] = useState(0);
  const [additionalPrompt, setAdditionalPrompt] = useState('');

  useDocumentTitle(`${t('general.pages.externalContents')}`);

  const resetForm = () => {
    setTemperature(llmConfigurationRag?.info?.temperature || 0.1);
    setMaxOutputTokens(llmConfigurationRag?.info?.maxOutputTokens || 200);
    setAnswerSourceMinScore(llmConfigurationRag?.info?.answerSourceMinScore || 0);
    setAdditionalPrompt(llmConfigurationRag?.info?.additionalPrompt || '');
  };

  const submitForm = () => {
    saveLLMConfigurationRAG(temperature, maxOutputTokens, answerSourceMinScore, additionalPrompt);
  };

  /**
   * Called when config is loaded, saved or restored
   */
  useEffect(() => {
    resetForm();
  }, [llmConfigurationRag]);

  return (
    <FormWithMarginBottom $marginBottom={'60px'}>
      <CreateWrapper>
        <div className='container-wrapper'>
          <CustomLLMColumn>
            <div>
              <CardTitleContainer>
                <h1>{t('externalContents.llmConfiguration.title')}</h1>
              </CardTitleContainer>
              <CardSubTitleContainer>
                <h2>{t('externalContents.llmConfiguration.subtitle')}</h2>
              </CardSubTitleContainer>
            </div>
            <HorizontalGroup>
              <CardLlmConfiguration
                title={t('externalContents.llmConfiguration.config.rag.temperature.title') + ' : ' + temperature}
                $marginRight={'10px'}
              >
                <CardLlmConfigurationDescription>
                  {t('externalContents.llmConfiguration.config.rag.temperature.description')}
                </CardLlmConfigurationDescription>
                <Slider
                  value={temperature}
                  min={0}
                  max={1}
                  step={0.01}
                  onChange={(e) => setTemperature(Number(e.value))}
                />
                <SliderBottomLabel>
                  <p>{t('externalContents.llmConfiguration.config.rag.temperature.slider.left')}</p>
                  <p>{t('externalContents.llmConfiguration.config.rag.temperature.slider.middle')}</p>
                  <p>{t('externalContents.llmConfiguration.config.rag.temperature.slider.right')}</p>
                </SliderBottomLabel>
              </CardLlmConfiguration>
              <CardLlmConfiguration
                title={t('externalContents.llmConfiguration.config.rag.outputToken.title') + ' : ' + maxOutputTokens}
                $marginLeft={'10px'}
                $marginRight={0}
              >
                <CardLlmConfigurationDescription>
                  {t('externalContents.llmConfiguration.config.rag.outputToken.description')}
                </CardLlmConfigurationDescription>
                <Slider
                  value={maxOutputTokens}
                  min={10}
                  max={390}
                  step={5}
                  onChange={(e) => setMaxOutputTokens(Number(e.value))}
                />
                <SliderBottomLabel>
                  <p>{t('externalContents.llmConfiguration.config.rag.outputToken.slider.left')}</p>
                  <p>{t('externalContents.llmConfiguration.config.rag.outputToken.slider.middle')}</p>
                  <p>{t('externalContents.llmConfiguration.config.rag.outputToken.slider.right')}</p>
                </SliderBottomLabel>
              </CardLlmConfiguration>
            </HorizontalGroup>
            <HorizontalGroup>
              <CardLlmConfiguration title={t('externalContents.llmConfiguration.config.rag.additionalPrompt.title')}>
                <CustomLLMColumn>
                  <CardLlmConfigurationDescription $height={'40px'}>
                    {t('externalContents.llmConfiguration.config.rag.additionalPrompt.description')}
                  </CardLlmConfigurationDescription>
                  <InputTextarea
                    value={additionalPrompt}
                    placeholder={t('externalContents.llmConfiguration.config.rag.additionalPrompt.placeholder')}
                    onChange={(e) => setAdditionalPrompt(e.target.value)}
                  />
                </CustomLLMColumn>
              </CardLlmConfiguration>
              <CardLlmConfiguration
                title={
                  t('externalContents.llmConfiguration.config.rag.answerSourceMinScore.title') +
                  ' : ' +
                  answerSourceMinScore
                }
                $marginLeft={'10px'}
                $marginRight={0}
              >
                <CardLlmConfigurationDescription>
                  {t('externalContents.llmConfiguration.config.rag.answerSourceMinScore.description')}
                </CardLlmConfigurationDescription>
                <Slider
                  value={answerSourceMinScore}
                  min={0}
                  max={1}
                  step={0.05}
                  onChange={(e) => setAnswerSourceMinScore(Number(e.value))}
                />
              </CardLlmConfiguration>
            </HorizontalGroup>
            <HorizontalGroup className={'align-right'}>
              <Button
                label={t('general.update')}
                icon='icon-check'
                onClick={(e) => {
                  e.preventDefault();
                  submitForm();
                }}
              />
            </HorizontalGroup>
          </CustomLLMColumn>
        </div>
      </CreateWrapper>
    </FormWithMarginBottom>
  );
};

export default LLMConfiguration;
