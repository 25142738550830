import { parse } from 'shell-quote';

export interface CurlRequest {
  endpoint: string;
  headers: KeyValue[];
  parameters: KeyValue[];
}

export const convertStringToCurlRequest = (request: string): CurlRequest => {
  const parsed = parse(request);

  const curlRequest: CurlRequest = { endpoint: '', headers: [], parameters: [] };

  for (let i = 0; i < parsed.length; i++) {
    const token = parsed[i];

    if (typeof token === 'string' && token.startsWith('http')) {
      curlRequest.endpoint = token;
    } else if (token === '-H' || token === '--header') {
      const nextToken = parsed[i + 1];
      if (typeof nextToken === 'string') {
        const header = (nextToken as string).split(': ');
        curlRequest.headers.push({ key: header[0], value: header[1] });
      }
      i++;
    } else if (token === '--data-urlencode') {
      const nextToken = parsed[i + 1];
      if (typeof nextToken === 'string') {
        const param = (parsed[i + 1] as string).split('=');
        curlRequest.parameters.push({ key: param[0], value: param[1] });
      }
      i++;
    }
  }

  return curlRequest;
};

export const buildCurlRequestFromData = (request?: string, headers?: KeyValue[], parameters?: KeyValue[]): string => {
  let curl = "curl -s -L -X POST '" + request + "' \\\n";

  if (headers && headers.length > 0) {
    headers.forEach(
      (header, index) =>
        (curl +=
          "\t-H '" +
          header.key +
          ': ' +
          header.value +
          (index === headers.length - 1 && (!parameters || parameters.length === 0) ? "'" : "' \\\n"))
    );
  }

  if (parameters && parameters.length > 0) {
    parameters.forEach(
      (param, index) =>
        (curl +=
          "\t--data-urlencode '" + param.key + '=' + param.value + (index === parameters.length - 1 ? "'" : "' \\\n"))
    );
  }
  return curl;
};
