import ConfigurationsRag from './Components/Rag/ConfigurationsRag';
import docVersion from '../../version.json';
import { InnerContainer, MainContainer, VersionContainer } from '../../HOC/WithLayout/Style';
import HeaderContainer from '../../Components/Header/HeaderContainer';
import CustomLLM from './Components/CustomLLM/CustomLLM';

const ExternalContents = () => {
  const renderVersion = () => docVersion?.branch && <VersionContainer>{docVersion.branch}</VersionContainer>;

  return (
    <MainContainer>
      <HeaderContainer />
      <InnerContainer>
        {renderVersion()}
        <ConfigurationsRag />
        <CustomLLM />
      </InnerContainer>
    </MainContainer>
  );
};

export default ExternalContents;
