import content from './content.json';
import contentSchema from './content-schema.json';
import { Release } from '../../../../../typings/states';
import nextRelease from '../chatbox_edge_2025-04-15/chatbox_edge_2025-04-15';
import contentPatchToNextVersion from './content-patch-to-next-version.json';

const release: Release = {
  version: 'chatbox_edge_2025-04-01',
  content: content,
  contentSchema: contentSchema,
  contentPatchToNextVersion: contentPatchToNextVersion,
  nextRelease: nextRelease,
};

export default release;
