import { APP_PATH } from 'src/Tools/Constants';
import Icon from 'src/Components/Icon/Icon';
import { PanelActionWraper } from '../Style';
import iconImport from 'src/Assets/Images/icon-import-code.svg';
import iconNewConfig from 'src/Assets/Images/icon-cog-wheel.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface PanelActionProps {
  type: 'import' | 'newConfig';
  importConfig?: () => void;
}

const PanelAction = ({ importConfig, type }: PanelActionProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleCreateNewConfig = () => navigate(APP_PATH.NEW_CONFIG);

  const renderImport = () => (
    <div className='inner-content' onClick={importConfig}>
      <img src={iconImport} />
      <div className='title'>{t('emptyConfig.panel.import')}</div>
      <ul>
        <li>
          <Icon icon='close-circle' color='red' />
          <div className='text disabled'>{t('emptyConfig.panel.stepper')}</div>
        </li>
        <li>
          <Icon icon='close-circle' color='red' />
          <div className='text disabled'>{t('emptyConfig.panel.advanced')}</div>
        </li>
        <li>
          <Icon icon='check-circle' color='green' />
          <div className='text'>{t('emptyConfig.panel.publish')}</div>
        </li>
      </ul>
      <Icon icon='upload' />
    </div>
  );

  const renderNewConfig = () => (
    <div className='inner-content' onClick={handleCreateNewConfig}>
      <img src={iconNewConfig} />
      <div className='title' data-testid='create-configuration'>
        {t('emptyConfig.panel.create')}
      </div>
      <ul>
        <li>
          <Icon icon='check-circle' color='green' />
          <div className='text'>{t('emptyConfig.panel.stepper')}</div>
        </li>
        <li>
          <Icon icon='check-circle' color='green' />
          <div className='text'>{t('emptyConfig.panel.advanced')}</div>
        </li>
        <li>
          <Icon icon='check-circle' color='green' />
          <div className='text'>{t('emptyConfig.panel.publish')}</div>
        </li>
      </ul>
      <Icon icon='arrow-right' />
    </div>
  );

  const renderContent = () => {
    switch (type) {
      case 'import':
        return renderImport();
      case 'newConfig':
        return renderNewConfig();
    }
  };

  return <PanelActionWraper>{renderContent()}</PanelActionWraper>;
};

export default PanelAction;
