import styled from 'styled-components';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';

export const IconTrigger = styled.div`
  position: relative;
  display: flex;
  border-radius: 36px;
  width: 36px;
  height: 36px;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: rgba(112, 145, 216, 0.1);
  }
`;

export const CreateWrapper = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.color_grey__300};
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  transition: all ease 0.5s;

  .fixed-height {
    height: 400px;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .container-wrapper {
    display: flex;
    align-items: start;
    justify-content: space-between;
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
  }

  .container-wrapper-reverse {
    flex-direction: row-reverse;
  }

  h1 {
    font-size: 28px;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.color_black};
  }

  h2 {
    font-size: 18px;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.color_black};
  }

  h3 {
    font-size: 14px;
    font-style: italic;
    color: ${({ theme }) => theme.color_grey__500};
  }
`;

export const FormWithMarginBottom = styled.form<{ $marginBottom?: string }>`
  margin-bottom: ${(props) => props.$marginBottom || '40px'};
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .input-wrapper {
    gap: 10px;
    margin: 0;
  }

  .p-invalid {
    border-color: ${({ theme }) => theme.color_critical};
  }

  .p-dialog-header-icon {
    display: contents;
  }

  .actions-modal {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }

  .delete-button {
    background-color: ${({ theme }) => theme.color_critical};
    border: none;
  }

  h1 {
    font-size: 28px;
    color: ${({ theme }) => theme.color_grey__600};
  }

  h2 {
    font-size: 16px;
    white-space: break-spaces;
    color: ${({ theme }) => theme.color_grey__500};
  }
`;

export const HeaderWrapper = styled.div`
  .p-dialog-header {
    margin-top: 20px;
  }

  h1 {
    font-size: 20px;
    color: ${({ theme }) => theme.color_black};
    display: contents;
  }
`;

export const CardTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  padding: 1rem 1rem;

  justify-content: space-between;
  align-items: center;
`;

export const CardSubTitleContainer = styled.div`
  padding-left: 1rem;
  margin-top: -10px;
`;

export const CustomLLMColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .padding-left-right-1rem {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .align-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .align-right {
    justify-content: end;
  }
`;

export const CustomLLMColumnWithOverflowHidden = styled(CustomLLMColumn)<{ $maxHeight: string }>`
  overflow: hidden;
  max-height: ${(props) => props.$maxHeight || '100%'};
`;

export const HorizontalGroup = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;

  .text {
    width: 100%;
  }
`;

export const SpecificConfigurationHorizontalGroup = styled(HorizontalGroup)`
  justify-content: space-between;
  align-items: center;
  text-align: left;

  label {
    font-size: 1.5rem;
    width: 50%;
  }

  .input-wrapper {
    width: 100%;
    margin: 0;
  }
`;

export const LlmTypeDropdown = styled(Dropdown)`
  .p-dropdown {
    height: 60px;
  }

  .p-dropdown-item {
    justify-content: center;
  }
`;

export const CustomLLMCard = styled(Card)`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
  height: 130px;

  text-overflow: clip;
  overflow: hidden;

  .p-card-title {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  .p-card-body {
    width: 100%;
    justify-content: center;
    text-align: center;

    .no-option {
      padding-top: 83px;
    }
  }

  .p-invalid {
    border-color: ${({ theme }) => theme.color_critical};
  }

  .height-200-px {
    height: 200px;
  }

  .btn-config {
    overflow-wrap: anywhere;
    font-size: 1.3em;
  }
`;

export const CustomLLMCardWithScroll = styled(CustomLLMCard)`
  .p-card-content {
    overflow: scroll;
    height: 100%;
  }
`;

export const CustomLLMCardFixedWidth = styled(CustomLLMCard)<{ $width: string }>`
  min-width: ${(props) => props.$width};
  max-width: ${(props) => props.$width};
`;

export const Step = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: solid #c3c8ce 1px;
  text-align: center;
  position: absolute;
  margin-top: -57px;
  background-color: white;
  margin-left: 10px;
  font-size: 1em;
`;

export const LlmDropdownTemplate = styled.img`
  margin: auto;
  width: 75%;
`;

export const LlmDropdownItemTemplate = styled.img`
  margin: auto;
  width: 150px;
`;

export const MessageAlignLeft = styled(Message)`
  justify-content: left;

  .p-inline-message-text {
    font-size: 1.5em;
  }
`;

export const OverflowYScroll = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
`;
