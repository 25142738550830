import { Container, InnerContent } from 'src/Pages/Edition/Style';

import Button from 'src/Components/Button/Button';
import ControlledSwitch from 'src/Components/Switch/ControlledSwitch';
import { DisplayProps } from '../Display';
import { Divider } from 'src/Components/Divider/Style';
import Icon from 'src/Components/Icon/Icon';
import ImageUpload from 'src/Components/ImageUpload/ImageUpload';
import Message from 'src/Components/Message/Message';
import { Select } from 'src/Components/ImageUpload/Style';
import { SubField } from 'src/Components/Style';
import { UPLOAD_TYPE } from 'src/Tools/Constants';
import { useTranslation } from 'react-i18next';

interface IntegrationProps extends DisplayProps {}

const Integration = ({ control, imageUpload, watch, setValue, imagesData }: IntegrationProps) => {
  const { t } = useTranslation();

  const imagesInfo = [
    {
      title: t('edition.advanced.images.onboarding1'),
      src: imagesData?.onboarding1,
      type: 'ONBOARDING1',
      field: 'imagesData.onboarding1',
    },
    {
      title: t('edition.advanced.images.onboarding2'),
      src: imagesData?.onboarding2,
      type: 'ONBOARDING2',
      field: 'imagesData.onboarding2',
    },
    {
      title: t('edition.advanced.images.onboarding3'),
      src: imagesData?.onboarding3,
      type: 'ONBOARDING3',
      field: 'imagesData.onboarding3',
    },
  ];

  const imageItems = watch('content.onboarding.items')
    ? watch('content.onboarding.items')?.map((item, index) => ({
        ...imagesInfo?.[index],
        ...item,
      }))
    : [];

  const handleImageUpload = (file: File, type: string, field: string) => imageUpload(file, type, field);

  const toggleImageVisibility = (index: number) => {
    const imageToHide = watch('content.onboarding.items')?.map((item, i) => {
      if (i !== index) return item;
      return { ...item, image: { ...item.image, hidden: !item.image.hidden } };
    });

    setValue('content.onboarding.items', imageToHide);
  };

  const handleDisableStep = (value, index) => {
    const items = watch('content.onboarding.items') ? [...watch('content.onboarding.items')] : [];

    const updatedItems = items?.map((item, i) => {
      if (i !== index) return item;
      return { ...item, disabled: value };
    });

    setValue('content.onboarding.items', updatedItems);
  };

  const turnOffImage = (index: number) => {
    const options = (
      <div className='container-select-icon'>
        <Select
          value={imageItems[index]?.disabled === false ? 'enabled' : 'disabled'}
          onChange={(e) => {
            handleDisableStep(e.target.value === 'disabled', index);
          }}
        >
          <option value='disabled'>{t('edition.advanced.images.inactive')}</option>
          <option value='enabled'>{t('edition.advanced.images.active')}</option>
        </Select>
        <Icon className='caret-down' color='black' icon='icon-caret-down' size={10} />
      </div>
    );
    return options;
  };

  const renderImagesUpload = () =>
    imageItems?.map((image, index) => {
      return (
        <div key={image.type + index}>
          <ImageUpload
            key={image.type}
            src={image.src}
            title={image.title}
            size='large'
            onUpload={(file) => handleImageUpload(file, image.type, image.field)}
            accept={UPLOAD_TYPE.IMAGE}
            disabled={image.disabled}
            hidden={image.image.hidden}
            extraContent={
              <>
                <Button
                  iconPos='right'
                  icon={image.hidden ? 'icon-eye' : 'icon-invisible'}
                  className='p-button-text p-button-secondary'
                  onClick={() => toggleImageVisibility(index)}
                  label={image.hidden ? t('edition.advanced.images.display') : t('edition.advanced.images.hide')}
                  disabled={image.disabled}
                />
                {turnOffImage(index)}
              </>
            }
          />
          {index !== imageItems.length - 1 && <Divider />}
        </div>
      );
    });

  return (
    <InnerContent className='integration'>
      <ControlledSwitch
        control={control}
        label={t('edition.advanced.field.onboarding.label')}
        subtitle={t('edition.advanced.field.onboarding.subtitle')}
        icon='hand'
        name='content.onboarding.enable'
      />
      <Container hidden={!watch('content.onboarding.enable')}>
        <Divider />
        <SubField>
          <div className='onboarding-images'>{renderImagesUpload()}</div>
        </SubField>
        <Message severity='info' text={t('edition.advanced.images.info')} />
      </Container>
    </InnerContent>
  );
};

export default Integration;
