import 'src/Services/i18n';

import { persistor, store } from './Redux/configureStore';

import App from './App';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { ReduxRouter } from '@lagunovsky/redux-react-router';
import { Theme } from './Assets/Styles/Theme';
import { ThemeProvider } from 'styled-components';
import { createRoot } from 'react-dom/client';
import { history } from './Redux/rootReducer';
import UserProvider from './Contexts/UserContext';
import CallBotConfigProvider from './Contexts/CallBotContext';
import RagConfigProvider from './Contexts/RagConfigContext';
import './Index.css';
import CustomLLMProvider from './Contexts/CustomLLMContext';
import LLMConfigurationProvider from './Contexts/LLMConfigurationContext';

/**
 * Render Root Element
 */
const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <ReduxRouter
      history={history}
      store={store}
      children={
        <PersistGate loading={null} persistor={persistor}>
          <UserProvider>
            <CallBotConfigProvider>
              <CustomLLMProvider>
                <RagConfigProvider>
                  <LLMConfigurationProvider>
                    <ThemeProvider theme={Theme}>
                      <App />
                    </ThemeProvider>
                  </LLMConfigurationProvider>
                </RagConfigProvider>
              </CustomLLMProvider>
            </CallBotConfigProvider>
          </UserProvider>
        </PersistGate>
      }
    />
  </Provider>
);
