import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentBot } from '../Redux/Bot/selectors';
import { useUserContext } from './UserContext';
import { useTranslation } from 'react-i18next';
import { getLLMConfigurationRAGForBotAndRagConfig, getToken, updateLLMConfigurationRAG } from '../Api/Tools/Api';
import { toastError, toastSuccess } from '../Tools/Toast';
import { useRagConfig } from './RagConfigContext';

interface LLMConfigurationProviderProps {
  llmConfigurationRag: API_BACKEND.Schemas.LLMConfigurationRAGDTO | null;
  saveLLMConfigurationRAG: (
    temperature: number,
    maxOutputTokens: number,
    answerSourceMinScore: number,
    additionalPrompt: string
  ) => void;
  setLoading: (loading: boolean) => void;
  loading: boolean;
}

export const useLLMConfiguration = () => useContext<LLMConfigurationProviderProps>(LLMConfigurationContext);
export const LLMConfigurationContext = createContext({} as LLMConfigurationProviderProps);

const LLMConfigurationProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();

  const { selectedRagConfiguration } = useRagConfig();

  const [llmConfigurationRag, setLlmConfigurationRag] = useState<API_BACKEND.Schemas.LLMConfigurationRAGDTO | null>(
    null
  );

  const [loading, setLoading] = useState<boolean>(true);
  const botUUID = useSelector(getCurrentBot)?.botUUID;
  const { logout } = useUserContext();

  useEffect(() => {
    if (botUUID && selectedRagConfiguration?.id) {
      setLoading(true);
      getLLMConfigurationRAGForBotAndRagConfig(botUUID, selectedRagConfiguration.id, getToken())
        .then((res: any) => {
          setLlmConfigurationRag(res);
        })
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          if (error?.response?.status == '401') {
            logout();
          }
          console.log(error);
        });
    }
  }, [botUUID, selectedRagConfiguration]);

  const saveLLMConfigurationRAG = (
    temperature: number,
    maxOutputTokens: number,
    answerSourceMinScore: number,
    additionalPrompt: string
  ) => {
    const newLLMConfigurationRag: API_BACKEND.Schemas.LLMConfigurationRAGDTO = {
      ...llmConfigurationRag,
      type: 'RAG',
      info: {
        temperature: temperature,
        maxOutputTokens: maxOutputTokens,
        answerSourceMinScore: answerSourceMinScore,
        additionalPrompt: additionalPrompt,
      },
    };

    updateLLMConfigurationRAG(botUUID, selectedRagConfiguration?.id, newLLMConfigurationRag, getToken())
      .then((res) => {
        setLlmConfigurationRag(res);
        toastSuccess(t('externalContents.llmConfiguration.config.rag.action.message.updated'));
      })
      .catch((error: any) => {
        if (error?.response?.status == '401') {
          console.log(error);
          logout();
        } else {
          toastError(t('externalContents.llmConfiguration.config.rag.action.message.updateError'));
        }
      });
  };

  const props = {
    llmConfigurationRag,
    saveLLMConfigurationRAG,
    setLoading,
    loading,
  };

  return <LLMConfigurationContext.Provider children={children} value={props} />;
};

export default LLMConfigurationProvider;
